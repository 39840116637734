.unit-pricelist-v1 {
	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}

		}

		.row-21237 {
			tr:nth-child(2) {
				display: table-row !important;
			}

			.subrow-21237 {
				display: none;
			}
		}
	}
}