.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);
		padding-left: 5vw;
		padding-right: 5vw;

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		.col-lg-3 {
			@media all and (min-width: 992px) and (max-width: 1199px) {
				-webkit-box-flex: 0;
				flex: 0 0 50%;
				max-width: 50%;
				margin-bottom: 30px !important;
			}
		}

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			max-width: 250px;
			width: 100%;
			height: auto;
			margin-top: 0;
			padding-bottom: 20px;
			padding: 15px;
			border-radius: 8px;
			background: #fff;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		.address-box {
			li {
				position: relative;
				padding: 0 0 0 30px;
				margin: 0 0 15px 0;

				i {
					position: absolute;
					left: 0;
					top: 5px;
				}

			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 250px;
			width: 100%;

			li {
				margin: 0 10px !important;

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 26px;
					color: var(--footer-font-color);

				}
			}

		}

		.list-unstyled {
			display: flex;
			flex-wrap: wrap;

			li {
				width: 50%;
				padding-right: 10px;
			}

		}


		.qr-code-bl {
			max-width: 250px;

			img {
				border-radius: 10px;
			}
		}


		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		.row {
			align-items: Center;

			.v-logo {
				text-align: right;

				img {
					height: 30px;
					margin-bottom: 0 !important;
				}
			}


		}
	}

}