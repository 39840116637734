.questions-information-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);
	color: var(--color-white);

	a {
		color: var(--color-white);
	}

	h3 {
		margin: 0 0 15px 0;
		color: var(--color-white);
	}

	ul {
		margin: 0;

		li {
			margin: 0 0 7px 0;
		}
	}
}